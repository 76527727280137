.isGrouped {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  :global(.tag) {
    margin-right: 0.5rem;
  }

  :global(:last-child) {
    margin-right: 0rem;
  }
}
