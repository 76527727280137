.checkboxes {
  .count {
    font-size: 1.25rem;
    color: #8d9494;
    margin-top: -0.25rem;
    padding-right: 0.25rem;
    padding-left: 0.75rem;
  }

  .content {
    display: flex;
    align-items: center;
    margin: -0.25rem;
    padding: 0.25rem;

    & :global(.ck-checkbox) {
      flex: 1;
      min-width: 0;

      :global(.ui.checkbox) {
        line-height: 1.875rem !important;
      }

      label {
        color: #585858;
        padding-left: 3rem !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: clamp(18rem, 18vw, 23rem);
      }
    }
  }

  .paginateButtonColumn {
    padding-top: 1.5rem !important;
    padding-right: 0.5rem !important;
  }
}
