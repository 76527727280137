.buttonReset {
  background: none;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropDown {
  border: 1px solid transparent;

  button {
    @extend .buttonReset;

    &:hover,
    &:active,
    &:focus {
      @extend .buttonReset;
    }

    &:hover {
      :global(.profile-icon) {
        position: relative;
        overflow: hidden;
  
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.15);
        }
      }
    }
  }

  .dropDownTrigger {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  :global(.ck-popup-menu-trigger) {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
    top: calc(1.25em + 4px);
    z-index: 0;
  }
}

:global(.ui.bottom.center.basic.popup.transition.visible.ck-popup-menu-parent) {
  margin-top: 0.8em;
  margin-left: -0.8em;
}

.dropDownPopup,
.dropDownWrapper {
  border: 1px solid transparent;
}