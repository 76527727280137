.isClickable:hover {
  opacity: 0.8;
}

.isSeparator::after {
  content: attr(data-separator);
  padding-left: 0.75rem;
  font-size: small;
  justify-self: baseline;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.control:last-child .isSeparator::after {
  content: "";
}

.tags {
  padding-bottom: 1rem;
  color: #585858;
}
