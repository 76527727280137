.datetimePicker {
  :global(.ck-label-text) {
    font-weight: 700;
  }

  :global(.ck-date-time-picker .rw-datetime-picker .rw-popup-transition) {
    padding: 0 0.2rem;
  }

  :global(.ck-date-time-picker .rw-datetime-picker input.rw-input) {
    background: white;
  }

  :global(.ck-date-time-picker .rw-select.rw-select-bordered) {
    background: white;
  }
}
