.trigger {
  cursor: pointer;
  user-select: none;
}

.trigger:not(.isActive),
.contentIsActive {
  border-bottom: 1px solid #e6e7e7;
}

.contentIsInActive {
  display: none !important;
}
