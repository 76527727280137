.spinner {
  @keyframes spinner-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  .wrapper {
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 500rem;
      border-style: solid;
    }

    &:before {
      border-color: rgba(0, 0, 0, 0.15);
    }

    &:after {
      border-color: #ffffff transparent transparent;
      box-shadow: 0px 0px 0px 1px transparent;
      animation: spinner-animation 1.6s linear;
      animation-iteration-count: infinite;
    }

    $sizes: "xs" 16px 12px -0.35rem 0.1rem,
      "sm" 24px 20px -0.75rem 0.25rem,
      "md" 32px 28px -1.125rem 0.3rem,
      "lg" 64px 60px -2.45rem 0.5rem,
      "xl" 128px 124px -5.15rem 0.6rem;

    @each $size in $sizes {
      &.#{nth($size, 1)} {
        width: #{nth($size, 2)};
        height: #{nth($size, 2)};

        &:before,
        &:after {
          width: #{nth($size, 3)};
          height: #{nth($size, 3)};
          margin: #{nth($size, 4)} 0 0 #{nth($size, 4)};
          border-width: #{nth($size, 5)};
        }
      }
    }
  }
}