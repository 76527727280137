@import "../../styles/colors.scss";

$PALE_BLUE: #d7f5f4;

.tr {
  &:hover {
    background-color: $PALE_BLUE;
  }

  &.isCheckable {
    :global(td):first-child {
      width: 1em;
      padding: 0 0 0 0.5em;
      border: 0;
      background-color: white;
    }

    &:hover,
    &.isChecked {
      background-color: $PALE_BLUE;
    }
  }
}

:global(thead) .isIndeterminate th {
  transform: translate3d(0, 0, 0);
}

.isIndeterminate {
  :global(.ck-checkbox .ui.checkbox label:before) {
    color: #ffffff;
    text-align: center;
    line-height: 1.5rem;
    background: $teal-300;
    border: none;
    content: "\2043";
    font-size: 2.625em;
  }
}

.isUnhoverable {
  &:hover {
    background-color: initial;
  }
}
