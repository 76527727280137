.isRounded {
  border-radius: 290429px;
}

.tag {
  display: inline-block;
  padding: 0.25rem 1.25rem;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.tag > span:not(:first-child) {
  margin-left: 0.5em;
}

.is-white {
  border: 1px solid #b2b8b8;
  background-color: white;
  color: #222;
}

.is-warning {
  background-color: #f4f5ba;
  color: rgba(0, 0, 0, 0.7);
}

.is-error {
  background-color: #ff475d;
  color: white;
}

.is-accent {
  background-color: #b2ebea;
}

.is-medium {
  font-size: 1.25rem;
}

.is-grey {
  background-color: #8d9494;
}

.is-action {
  background-color: #2db364;
}

.is-orange {
  background-color: #f07937;
  color: white;
}
