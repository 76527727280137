.editableTitle {
  :global(.ck.input .ui.input) {
    border: none !important;
  }

  :global(.ck.input .ui.input input) {
    font-size: 2.4rem !important;
    font-family: "Roboto Condensed Regular", sans-serif;
    max-width: 100%;
    padding: 0;
    letter-spacing: 0.02em;
    color: #394242;
  }

  :global(.ck.input .ui.input.focus),
  :global(.ck.input .ui.input:focus),
  :global(.ck.input .ui.input:hover) {
    box-shadow: inset 0 0 0 1px #92dedd;
  }
}
