.paragraph {
  font-size: 1rem;
}

.isBreakAllWord {
  word-break: break-all;
}

.isBreakWord {
  word-wrap: break-word;
}

.is-title-spacing {
  letter-spacing: 0.2px;
}

.is-mini {
  font-size: 0.667rem;
}

.is-tiny {
  font-size: 0.833rem;
}

.is-small {
  font-size: 1rem;
}

.is-medium {
  font-size: 1.167rem;
}

.is-large {
  font-size: 1.333rem;
}

.is-huge {
  font-size: 1.5rem;
}

.is-massive {
  font-size: 1.667rem;
}

.is-black {
  color: #000000;
}

.is-arsenic {
  color: #394242;
}

.is-nickel {
  color: #6c7373;
}

.is-default {
  font-size: 1.25rem;
}

.isMarginLess {
  margin: 0 !important;
}

.isBold {
  font-weight: bold;
}
