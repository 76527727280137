.multiValueContainer {
  font-weight: bold;
  
  &:before,
  &:after {
    content: "";
    clear: both;
    display: table;
  }

  .multiValueLabel,
  .multiValueRemove {
    float: left;
    color: #000000;
  }

  .multiValueLabel {
    width: calc(100% - 24px);
    padding: 1px 4px 0 4px;
  }

  .multiValueRemove {
    width: 24px;
    height: 18px;
    cursor: pointer;
    font-size: 12px;
    padding-top: 3px;

    > div {
      text-align: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        color: initial;
      }
    }
  }
}

.tag {
  padding-right: 2rem;
}

.error {
  color: black;
}

.delete {
  padding: 0;
  padding-top: 1rem;
  background: none;

  &:hover {
    background: none;
  }
}
