.column {
  display: flex;
  padding-left: 1rem;
  padding-top: 1rem;
  line-height: 1.5rem;

  &:global(:not(.is-narrow)) {
    flex-grow: 1;
  }
}

.isMultiline {
  flex-direction: column;
}

.isFullwidth {
  flex-grow: 1;
}

.isCenter {
  justify-content: center;
}

.isRight {
  justify-content: right;
}

.isPaddingLess {
  padding: 0;
}

.is1 {
  flex-basis: 8.33333%;
}

.is2 {
  flex-basis: 16.66667%;
}

.is3 {
  flex-basis: 25%;
}

.is4 {
  flex-basis: 33.33333%;
}

.is5 {
  flex-basis: 41.66667%;
}

.is6 {
  flex-basis: 50%;
}

.is7 {
  flex-basis: 58.33333%;
}

.is8 {
  flex-basis: 66.66667%;
}

.is9 {
  flex-basis: 75%;
}

.is10 {
  flex-basis: 83.33333%;
}

.is11 {
  flex-basis: 91.66667%;
}

.is12 {
  flex-basis: 100%;
}
