@import "../../styles/colors.scss";

.ck-strength-gauge {
  width: 100%;
  height: 2rem;
  background-color: #d9d9d9;
  border-radius: 2px;
  position: relative;
  line-height: 2rem;
  overflow: hidden;

  .strength-bar {
    width: auto;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .strength-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    line-height: 2rem;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  &.very-weak {
    .strength-bar {
      width: 20%;
      background-color: #841f2b;
    }

    .strength-text {
      color: #000000;
    }
  }

  &.weak {
    .strength-bar {
      width: 40%;
      background-color: #bd2d3e;
    }

    .strength-text {
      color: #000000;
    }
  }

  &.okay {
    .strength-bar {
      width: 60%;
      background-color: #ffa500;
    }

    .strength-text {
      color: #000000;
    }
  }

  &.good {
    .strength-bar {
      width: 80%;
      background-color: $dark-lime;
    }

    .strength-text {
      color: #ffffff;
    }
  }

  &.very-good {
    .strength-bar {
      width: 100%;
      background-color: #3ebd2d;
    }

    .strength-text {
      color: #ffffff;
    }
  }
}
