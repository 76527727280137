@import "../../styles/colors.scss";

.menu {
  :global(.ui.secondary.menu) {
    margin-left: 0;
    margin-right: 0;
  }

  :global(.ui.menu.vertical .menu) {
    padding-bottom: 0;
  }

  :global(.ui.menu div.item > a) {
    color: #4a4a4a;
    display: block;
    padding: 0.75rem;
  }

  :global(.ui.menu div.item > a:hover) {
    background: #d7f5f4;
  }

  :global(.ui.menu.secondary div.item:hover a) {
    color: $teal-500 !important;
  }

  :global(.ui.menu.vertical.secondary .item) {
    margin: 0;
  }
}

.isFullwidth {
  width: 100%;
}
