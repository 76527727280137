.card {
  :global(.ui.card) {
    &:hover {
      cursor: unset;
    }

    :global(.content) {
      text-align: left;
      justify-content: flex-start;
    }
  }

  :global(.ui.card:hover .content > div:first-child) {
    color: #6c7373;
  }
}