$BATTLESHIP_GREY: #6c7373;

.table {
  color: $BATTLESHIP_GREY;
  margin-bottom: 1.5rem;

  &.isFullwidth {
    width: 100%;
    flex-grow: 1;
  }

  :global(td),
  :global(th) {
    padding: 0.5rem 1rem;
  }

  :global(thead th) {
    color: $BATTLESHIP_GREY;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
  }
}
