.breadcrumbs {
  font-size: 1.25rem;

  .crumb {
    display: inline-block;
  }

  .divider {
    display: inline-block;
    padding-inline: 0.75rem;
  }
}
