.singleSelect {
  :global(.ck.dropdown .ui.dropdown.selection.active) {
    border-bottom: 1px solid #63cfcd;
  }
}

.singleSelect.isSearchable {
  :global(.ui.upward.dropdown > .menu) {
    top: auto !important;
    bottom: calc(100% - 1px);
    box-shadow: none;
  }

  :global(.ui.upward.active.selection.dropdown:hover .menu) {
    box-shadow: none;
  }

  :global(.ui.active.visible.search.selection.upward.dropdown) {
    border-radius: 0.2rem 0.2rem 0 0 !important;
  }
}
