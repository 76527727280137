$SEAFOAM_BLUE: #63cfcd;
$GREY_100: #e8ebeb;

.td {
  border-bottom: 1px solid $GREY_100;
  height: 4rem;
}

.td,
.td > * {
  vertical-align: middle;
}

.td > * {
  display: flex;
}

.isFullwidth {
  width: 100%;
  max-width: 0;
}

.isNowrap {
  white-space: nowrap;
}

.isBorderless {
  border-bottom: none;
}

.isBorderless {
  border-bottom: none;
}

.isCenter > * {
  justify-content: center;
}
