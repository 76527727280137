$minPageWidth: 1024px;
$maxPageWidth: 1440px;

html,
.cid,
.cid .root {
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  overflow: hidden;
  color: #6c7373;
  background-size: cover;
}

body.login {
  background: url("https://login.eccmp.com/uxlogin/ux/login/static/media/background.b29b13d1.svg");
  background-size: cover;
}

.ui.large.menu {
  font-size: 1rem;
}

.ui.container {
  width: 100%;
  padding: 0 3.5rem;
}

.ui.grid {
  margin: unset;
}

.ck.buttons[class*="medium horizontal-spacing"] {
  .ui.button {
    margin-right: 0;
  }
}

.ui.checkbox label {
  font-size: 1.25rem;
}

.ui.checkbox input {
  display: none;
}

.ui.menu.vertical .menu {
  padding-bottom: 0;
}

.ui.modal {
  font-size: 1.25rem;
}

.is-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ck.tooltip.tooltip--multiline[data-ck-tooltip]:after {
  white-space: normal;
}

::-ms-reveal {
  display: none;
}

.dimmable.dimmed > .modals.dimmer {
  backdrop-filter: blur(0.5rem);
}