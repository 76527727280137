.ck-paging-bullets {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  .bullet {
    width: 2rem;
    height: 2rem;
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #FFFFFF;
      border: 2px solid#D4D9D9;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    &.active,
    &.visited {
      &:before {
        border-color: #5EAFBE;
        background-color: #5EAFBE;
      }
    }
  }
}
