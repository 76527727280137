.popup:before {
  background-color: #4a4a4a !important;
  box-shadow: none !important;
}

.tooltipContent {
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: 0.25rem;
}

.isEllipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.popup .content) {
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.isBreakAll :global(.content) {
  word-break: break-all;
}
