.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(120vh);
  padding: 2rem 0;

  :global(.ui.card) {
    width: 370px;
    min-height: 542px;

    > :global(.content) {
      > :global(.description.text-overflow-wrap) {
        overflow: initial;
      }
    }
  }

  &:global(.is-medium .ui.card){
    width: 470px;
    min-height: 542px;
  }

  &.isScrollable {
    overflow: auto;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.copyright {
  text-align: center;
  position: fixed;
  bottom: 0;
  :global(.ck.ui.label.basic) {
    color: #8fbbc2;
  }
}
