.debouncedInput {
    .actionIcon {
		padding: 0.7rem;
        
		&:hover {
            :global(.ck-icon) {
                color: #6bd2d1;
            }
        }
    }

    :global(.ck.input.fluid.has-error-icon.error) {
        .actionIcon {
            &:hover {
                :global(.ck-icon) {
                    color: #6c7373;
                }
            }
        }
    }

    :global(.ck.input.fluid.has-error-icon.error .ck.tooltip) {
        &:before {
            border-color: #ff475d transparent;
        }
		
        &:after {
            background-color: #ff475d;
            text-align: center;
        }
    }
}
