.tag {
  background-color: #f4f5ba;
  margin-left: -1rem;
  border-top-right-radius: 290429px;
  border-bottom-right-radius: 290429px;
  position: relative;
  display: inline-flex;
  padding: 0.25rem 0.625rem 0.25rem 1.25rem;
  font-size: 0.875rem;

  &::before {
    height: 1px;
    width: 11px;
  }

  &::after {
    height: 11px;
    width: 1px;
  }

  &::before,
  &::after {
    background-color: currentColor;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
    font-size: 3rem;
  }

  &:hover {
    background: #e0e200;
  }
}
