.gridContainer {
  font-size: 1.25rem;
  line-height: 1.75rem;
  grid-auto-rows: max-content;
  grid-template-columns: auto auto 1fr;
}

.label {
  display: flex;
  font-weight: 700;
  padding-left: 20px;
}

.tooltip {
  position: absolute;
  transform: translate(-22px);
}
