$WHITE_TWO: #f7f7f7;
$GREY_100: #e8ebeb;
$CHARCOAL_GREY: #394242;

.th {
  background-color: $WHITE_TWO;
  text-align: left;
  height: 4rem;

  &.isSortable {
    cursor: pointer;
    user-select: none;
    :global(.ck-icon) {
      padding-left: 0.25rem;
      transform: translateY(0.125rem);
    }
  }

  &.isSortable:hover {
    opacity: 0.8;
  }

  &.isSortable:hover,
  &.isHighlight {
    background: $GREY_100;
    color: $CHARCOAL_GREY;
  }
}
