.dateInputPicker {
  input {
    font-size: 1rem !important;
  }

  :global(.ck-date-time-picker) {
    td, th {
      font-size: 1rem !important;
    }
  }
}

.clearSelection {
  display: block;
  text-align: center;
  width: 100%;
  padding: 0.25rem 1rem;
  color: #0f9997;
&:hover {
    color: #7B7B7B;
  }
}