.checkbox {
  &.isModified {
    :global(.ck-checkbox .ui.checkbox.checked label:before) {
      background-color: #ff475d;
    }

    :global(.ck-checkbox .ui.checkbox label:before) {
      border-color: #ff475d;
    }
  }
}

.isNoLabel :global(.ui.checkbox) {
  width: 22px;
}
