.refresh {
  position: absolute;
  right: 0;
  top: 0;
  background: #77d3c6;
  width: 21px;
  height: 21px;
  padding: 2px;
  padding-top: 3px;
  padding-left: 3px;
  margin: 5px;
  border-radius: 21px;
}

.div {
  position: relative;
}