.menuLink + .menuLink {
  display: block;
  border-top: 1px solid #e8ebea;
}

.item:hover {
  background: none !important;

  &:global(.is-clickable:hover) {
    cursor: pointer !important;
    background: #d7f5f4 !important;
  }
}
