.multiSelect {
  :global(.ui.selection.dropdown) {
    background: white;
  }

  :global(.ck.dropdown .ui.dropdown.multiple.dropdown > .label) {
    background-color: #b2ebea;
  }

  :global(.ui.multiple.dropdown > .label) {
    border-radius: 15px;
  }
}
