.menuLink {
  display: block;
  padding: 0.75rem;
  line-height: 1.5rem;
  width: 100%;
  min-width: 10rem;
  border-bottom: none;

  &:hover {
    cursor: pointer;
    background: #d7f5f4;
  }
}

.container {
  margin: 0.75rem;
  min-width: 18rem;
}

.isDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  color: #6c7373;
}
