.columns {
  display: flex;
  gap: 1rem;
}

.isMultiline {
  flex-direction: column;
}

.isFullwidth {
  flex-grow: 1;
}

.isGapless {
  gap: 0;
}

.isVcentered {
  align-items: center;
}

.isCenter {
  justify-content: center;
}

.isTop {
  align-items: flex-start;
}

.isWrap {
  flex-wrap: wrap;
}

.isPaddingBottomless {
  :global(.cd.column) {
    padding-bottom: 0;
  }
}

.tinyGap {
  gap: 0.833rem;
}
