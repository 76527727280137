.header {
  &.isGapless {
    :global(.ui.header .content) {
      padding: 0;
    }
  }

  &.isUppercase {
    :global(.content) {
      text-transform: uppercase !important;
    }
  }

  &.isDawnGrey {
    :global(.content) {
      color: #6c7373 !important;
    }
  }

  &.isTiny {
    :global(.content) {
      font-size: 1.2rem !important;
    }
  }
}
